import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../../src/Enroute Files/logo-jpg-new.png";
import logoLightImg from "../../../src/Enroute Files/logo-jpg-new.png";


export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24 ",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >


      {img ? (
        <img
          className={`block max-h-15 ${imgLight ? "dark:hidden" : ""}`}
          src={logoImg}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-13 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
