import React, { FC,createContext, useContext, useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { log } from "console";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { StaySearchContext } from "App";



const StaySearchForm: FC<{}> = () => {
  let newval = sessionStorage.getItem('searchdata');
  let initial 
  if (newval !== null) {
   let newvall = JSON.parse(newval);
    initial = newvall? newvall.value:''
  }



  const {startDate,endDate,guestAdultsInputValue,guestChildrenInputValue,guestInfantsInputValue,
  setEndDate,setGuestAdultsInputValue,setGuestChildrenInputValue,setGuestInfantsInputValue,setStartDate,setValue,value} = useContext(StaySearchContext)
  const navigate = useNavigate();
  const renderForm = () => {
   

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

      e.preventDefault();
      const searchParams = qs.stringify({
        value,
      });
      let searchdata = {
        value:value,
        startDate:startDate,
        endDate:endDate,
        guestAdultsInputValue:guestAdultsInputValue,
        guestChildrenInputValue:guestChildrenInputValue,
        guestInfantsInputValue:guestInfantsInputValue
      }
      sessionStorage.setItem('searchdata', JSON.stringify(searchdata));
      navigate(`/listing-stay?${searchParams}`);

    };
      
    return (
   
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 " onSubmit={handleSubmit} >
        <LocationInput className="flex-[1.5]" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput className="flex-1" />
      </form>

    );
  };

  return renderForm();
};

export default StaySearchForm;
