import React, { createContext, useState } from 'react';

export interface PackageData {
    id: number;
    name: string;
    category: string;
    main_place: string;
    places: string[];
    duration: string;
    inclusions: string[];
    exclusions: string[];
    average_rating: number;
    total_reviews: number;
    location:string;
    images: {
      id: number;
      image_url: string;
      image: string;
      package: number;
    }[];
    discount_percent: number;
    price: number;
    type: string;
    itinerary: any[]; 
    reviews: any[]; 
    is_active: boolean;
    discount_price: number;
    itinerary_pdf:string
  }
  
export const PackageDataContext = createContext<{
    packageData: PackageData;
    setPackageData: React.Dispatch<React.SetStateAction<PackageData>>;
  }>({
    packageData: {
      id: 0,
      name: '',
      category: '',
      main_place: '',
      places: [],
      duration: '',
      inclusions: [],
      exclusions: [],
      average_rating: 0,
      total_reviews: 0,
      images: [],
      discount_percent: 0,
      price: 0,
      type: '',
      location:'',
      itinerary: [],
      reviews: [],
      is_active:false,
      discount_price: 0,
      itinerary_pdf:''
    },
    setPackageData: () => {}
  });
  