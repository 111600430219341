
import { ListingStayContext } from "containers/ListingStayPage/ListingStayPage";
import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import twFocusClass from "utils/twFocusClass";

// const DEMO_PAGINATION: CustomLink[] = [
//   {
//     label: "1",
//     href: "#",
//   },
//   {
//     label: "2",
//     href: "#",
//   },
//   {
//     label: "3",
//     href: "#",
//   },
//   {
//     label: "4",
//     href: "#",
//   },
// ];
interface CustomLink {
  label: number;
  href: string;
}

function generatePagination(pageCount: number): CustomLink[] {
  const pagination: CustomLink[] = [];
  for (let i = 1; i <= pageCount; i++) {
    const link: CustomLink = {
      label: i,
      href: "#"
    };
    pagination.push(link);
  }
  return pagination;
}


export interface PaginationProps {
  className?: string;
  pageCount?: number;
}

const Pagination: FC<PaginationProps> = ({ className = "" ,pageCount=1 }) => {
  const pagination  = generatePagination(pageCount)
  const {currentPage,setCurrentPage} = useContext(ListingStayContext)
  const renderItem = (pag: CustomLink, index: number) => {
    if (pag.label===currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-teal-700  hover:bg-teal-600 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <button
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={()=>{
          setCurrentPage(pag.label)
        }}
      >
        
        {pag.label} 
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {pagination.map(renderItem)}
     
    </nav>
  );
};

export default Pagination;
