import React from "react";
import { Page, Document, StyleSheet, Image } from "@react-pdf/renderer";

import InvoiceTitle from "./components/InvoiceTitle";
import InvoiceNo from "./components/InvoiceNo";
import BillTo from "./components/BillTo";
import InvoiceThankYouMsg from "./components/InvoiceThankYouMsg";
import InvoiceItemsTable from "./components/InvoiceItemsTable";
import { InvoiceData } from "./PayPage";

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 200,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const PdfDocument = ({invoiceData}:{invoiceData:InvoiceData}) => {

    return (
        <Document>
            <Page size="A4" style={styles.page} >
                <Image style={styles.logo} src='https://res.cloudinary.com/dg047twga/image/upload/v1681901623/hhdzmojhkp7kpwjtjhtk.jpg' />
                <InvoiceTitle  />
                <InvoiceNo invoiceId={invoiceData.invoiceId}/>
                <BillTo billTo={invoiceData.user}/>
                <InvoiceItemsTable tableData= {invoiceData.clientInformation} />
                <InvoiceThankYouMsg />
            </Page>
        </Document>
    );
}

export default PdfDocument;