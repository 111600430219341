import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { ExperienceType } from "./BlogPage";

export interface Card13Props {
  className?: string;
  post: ExperienceType;
}

const Card13: FC<Card13Props> = ({ className = "", post }) => {
  const { date_published, experience_header, experience_sub_header,id,traveler_image,traveler_name,experience_main_image } = post;

  const datestring = new Date(date_published);
 let date = datestring.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
  return (
    <div className={`nc-Card13 relative flex ${className}`} data-nc-id="Card13">
      <div className="flex flex-col h-full py-2">
        <h2 className={`nc-card-title block font-semibold text-base`}>
          <Link to={'/blog-single'} className="line-clamp-2" title={experience_header} state={{'id':id}}>
            {experience_header}
          </Link>
        </h2>
        <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
          <span className="line-clamp-2"> {experience_sub_header}</span>
        </span>
        <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
          {date}
        </span>
        <div className="mt-auto hidden sm:block">
          <PostCardMeta meta={{
            author:{
              avatar:traveler_image,
              displayName:traveler_name
            },
            date:date_published
          }} />
        </div>
      </div>

      <Link
        to={''}
        className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
      >
        <NcImage
          containerClassName="absolute inset-0 "
          className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
          src={experience_main_image}
          alt={experience_header}
        />
        {/* <PostTypeFeaturedIcon
          className="absolute bottom-2 left-2"
          postType={postType}
          wrapSize="w-8 h-8"
          iconSize="w-4 h-4"
        /> */}
      </Link>
    </div>
  );
};

export default Card13;
