import axios from './UnAuthAxiosInstance'
import { clientId,clientSecret } from './env'

const login =(email,password) => 
    axios.post(`users/auth/token`,{
        'username':email,
        'password':password,
        'client_id':clientId,
        'client_secret':clientSecret,
        'grant_type':'password'
    }).then((response) => {
        let authTokens = {
            'accessToken': response.data?.access_token,
            'refreshToken': response.data?.refresh_token,
            'exp':response.data?.expires_in
        }
        localStorage.setItem('authTokens', JSON.stringify(authTokens));
    })


export default login;
