import { Tab } from "@headlessui/react";

import StayCard from "components/StayCard/StayCard";
import {
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { PackageData } from "data/types";
import axios from "axios";
import { API_URL } from "services/constants";
import { useAuthContext } from "App";
import toast from "react-hot-toast";
import Badge from "shared/Badge/Badge";

const AccountSavelists = () => {
  let [categories] = useState(["Packages", "Experiences", "Cars"]);
  const {setLoading}  = useAuthContext()
  const [change,setChange] = useState(false)
  const  [savedPackages,setSavedPackages] = useState<PackageData[]>([{
    id: 0,
    packageName: "",
    duration: "",
    averageRating: 0,
    totalReviews: 0,
    category: "",
    discount: 0,
    price: 0,
    images: [],
    type: "",
  }])
  useEffect(()=>{
    let authTokens = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens')!)
    : { accessToken: '', refreshToken: '', exp: '' };
    const createApiInstance = (accessToken:string) => {
      return axios.create({
        baseURL: API_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    };
    let api = createApiInstance(authTokens.accessToken);
      setLoading(true)
      api.post(`packages/wishlist_items/`).then((response)=>{
        setLoading(false)
       setSavedPackages(
        response.data.map((item:any)=>({
          id: item.id,
          packageName:item.name,
          duration:item.duration,
          averageRating:item.average_rating,
          totalReviews:item.total_reviews,
          category:item.category,
          discount:item.discount_percent,
          price:item.price,
          type:item.type,
          images:item.images.map((image:any) => image.image_url)
        }))
       )

     
      }).catch((error:any)=>{
        setLoading(false)
      })
  },[change])

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Save lists</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {['Packages'].map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {savedPackages.map((stay) => (
                    <StayCard key={stay.id} data={stay} setChange = {setChange} />
                  ))}
                 
                </div>
                {
                    savedPackages.length<1 && <>
                    <div className="flex mt-11 justify-center items-center">
                    <Badge color="red" name="You dont have anything in your bucket list " />
                    </div></>
                  }
        <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary href={'/listing-stay'}>Explore more packages</ButtonSecondary>
                </div>
                
              </Tab.Panel>
              {/* <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 8).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists



