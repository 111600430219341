import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import { useContext, useEffect, useState } from "react";
import { Booking } from "./AccountBilling";
import axios from "axios";
import { API_URL } from "services/constants";
import AuthContext from "contexts/AuthContext";
import { toast } from "react-hot-toast";
type CancelModalProps = {

  name: string;
  package_id: number;
  data:Booking
  setReload:React.Dispatch<React.SetStateAction<boolean>>
  reload:boolean;
}
export default function Modal({name,package_id,data,setReload,reload}:CancelModalProps) {

  const {setLoading,isLoggedIn,loading} = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false);
  useEffect(()=>{

  },[loading])
  let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  let api = createApiInstance(authTokens.accessToken);
 const onConfirm=(package_id:number)=>{
    api.patch(`users/booking_cancel/${package_id}`).then(()=>{
      setLoading(false)
      setReload(!reload)
      toast.success('Booking cancelled! Our team will contact you soon')}).catch(()=>{
        setReload(!reload)
        setLoading(false)
        toast.error('Booking cancellation failed! Please contact us to cancel manually')
      })
  }
    return (
        <>
        <ExperiencesCardH data={data} setShowModal={setShowModal} />

            {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-40"
                            onClick={() => setShowModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3 sm:flex">
                                    <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-red-600"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            Cancel Booking?
                                        </h4>
                                        <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                          Are you sure you want to cancel booking for {name}
                                        </p>
                                        <div className="items-center gap-2 mt-3 sm:flex">
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-800 focus:ring-2"
                                                onClick={() =>{
                                                    setLoading(true)
                                                    setShowModal(false)
                                                    onConfirm(data.id)
                                               
                                                  }
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                                onClick={() =>
                                                    setShowModal(false)
                                                }
                                            >
                                                Go Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}