// import StayCard from "components/StayCard/StayCard";
// import { DEMO_STAY_LISTINGS } from "data/listings";
// import React, { FC } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import CommonLayout from "./CommonLayout";

// export interface PageAddListing10Props {}

// const PageAddListing10: FC<PageAddListing10Props> = () => {
//   return (
//     <CommonLayout
//       nextBtnText="Publish listing"
//       index="10"
//       backtHref="/add-listing-9"
//       nextHref="/"
//     >
//       <>
//         <div>
//           <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
//           <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
//             Excellent, congratulations on completing the listing, it is waiting
//             to be reviewed for publication
//           </span>
//         </div>
//         <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
//         {/* FORM */}
//         <div>
//           <h3 className="text-lg font-semibold">This is your listing</h3>
//           <div className="max-w-xs">
//             <StayCard
//               className="mt-8"
//               data={{ ...DEMO_STAY_LISTINGS[0], reviewStart: 0 }}
//             />
//           </div>
//           <div className="flex items-center space-x-5 mt-8">
//             <ButtonSecondary href="/add-listing-1">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-5 w-5"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={1.5}
//                   d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
//                 />
//               </svg>
//               <span className="ml-3">Edit</span>
//             </ButtonSecondary>

//             <ButtonPrimary>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-5 w-5"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={1.5}
//                   d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
//                 />
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={1.5}
//                   d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
//                 />
//               </svg>
//               <span className="ml-3">Preview</span>
//             </ButtonPrimary>
//           </div>
//         </div>
//         {/*  */}
//       </>
//     </CommonLayout>
//   );
// };

// export default PageAddListing10;
import React from 'react'

function PageAddListing10() {
  return (
    <div>PageAddListing10</div>
  )
}

export default PageAddListing10