import React from "react";
import BtnLikeIcon from "./BtnLikeIcon/BtnLikeIcon";
import { useAuthContext } from "App";

const LikeSaveBtns = (props:any) => {
  const {isLoggedIn}  =useAuthContext()
  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        {props.itinerary&& <><a href={`${props.itinerary?props.itinerary:''}`} target="_blank"><span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
     
      <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1.4em"
      width="1.3em"

    >
      <path d="M.5 9.9a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 011 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z" />
      <path d="M7.646 11.854a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.293V1.5a.5.5 0 00-1 0v8.793L5.354 8.146a.5.5 0 10-.708.708l3 3z" />
    </svg>
          <span className="hidden sm:block ml-2.5">Download Itinerary</span>
          </span></a></>}
        {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span> */}
        <div className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">

        {  isLoggedIn&&<><BtnLikeIcon className=''  packageId={props.packageId} colorClass="text-black bg-white bg-opacity-30 hover:bg-opacity-50"/>

          <span className="hidden sm:block ml-2.5">Save</span></>}
        </div>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
