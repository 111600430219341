import { clientId,clientSecret } from "./env";
import axios from "./UnAuthAxiosInstance";
export function googleLogin(accessToken){
    axios
    .post(`users/auth/convert-token`, {
      token: accessToken,
      backend: "google-oauth2",
      grant_type: "convert_token",
      client_id: clientId,
      client_secret:clientSecret,
    })
    .then((res) => {
      let authTokens = {
        'accessToken':res.data.access_token,
        'refreshToken':res.data.refresh_token,
        'exp':res.data.expires_in,
    }

      localStorage.setItem('authTokens',JSON.stringify(authTokens));
    });
}