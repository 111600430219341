import React,{createContext, useContext,useEffect,useState} from "react";
import MyRouter from "routers/index";
import { Puff } from "react-loader-spinner";
import AuthContext from "contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import { PackageDataContext } from "contexts/packageDataContext";
import { PackageData } from "../src/contexts/packageDataContext";
import { number } from "yup";
import { UserData } from "containers/AccountPage/AccountPage";
import axios from "axios";
import { API_URL } from "services/constants";

export const useAuthContext = () => useContext(AuthContext);
export const UserContext = createContext<{
  userData: UserData ;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
}>({
  userData: {
    address:'',
    email:'',
    first_name:'',
    gender:'',
    last_name:'',
    phone_number:'',
    profile_image_url:''

  },
  setUserData: () => {},
});
export const StaySearchContext = createContext<{
  value: string;
  setValue: (value: string) => void;
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  guestAdultsInputValue: number;
  setGuestAdultsInputValue: React.Dispatch<React.SetStateAction<number>>;
  guestChildrenInputValue: number;
  setGuestChildrenInputValue: React.Dispatch<React.SetStateAction<number>>;
  guestInfantsInputValue: number;
  setGuestInfantsInputValue: React.Dispatch<React.SetStateAction<number>>;
}>({
  value: "",
  setValue: () => {},
  startDate: new Date(),
  setStartDate: () => {},
  endDate: new Date(),
  setEndDate: () => {},
  guestAdultsInputValue: 2,
  setGuestAdultsInputValue: () => {},
  guestChildrenInputValue: 1,
  setGuestChildrenInputValue: () => {},
  guestInfantsInputValue: 1,
  setGuestInfantsInputValue: () => {},
});

function App() {
  let currentState = localStorage.getItem('authtokens')?true:false;
  const [isLoggedIn,setLoggedIn] = useState(currentState);
  const [loading,setLoading] = useState(false)
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(
    new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);
  const [userData, setUserData] = useState<UserData>({
    address:'',
    email:'',
    first_name:'',
    gender:'',
    last_name:'',
    phone_number:'',
    profile_image_url:''

  });
  useEffect(()=>{
    let authTokens = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens')!)
    : { accessToken: '', refreshToken: '', exp: '' };
    const createApiInstance = (accessToken:string) => {
      return axios.create({
        baseURL: API_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    };
    if (isLoggedIn){
    let api = createApiInstance(authTokens.accessToken);
      setLoading(true)
  
      api.get('users/user_profile/').then((response)=>{
        setLoading(false)
        setUserData(response.data)
        
      }).catch((error:any)=>{
        setLoading(false)
      })
    }
  },[])
  let packages: PackageData;
  const storedData = localStorage.getItem('packageData');
  if (storedData) {
    packages = JSON.parse(storedData);
  } else {
    packages = {
      id: 0,
      name: '',
      category: '',
      main_place: '',
      places: [],
      duration: '',
      inclusions: [],
      exclusions: [],
      average_rating: 0,
      total_reviews: 0,
      images: [],
      discount_percent: 0,
      price: 0,
      type: '',
      location:'',
      itinerary: [],
      reviews: [],
      is_active: false,
      itinerary_pdf:'',
      discount_price:0
    }
  }
  const [packageData, setPackageData] = useState<PackageData>(packages);
  return (
    <AuthContext.Provider value={{isLoggedIn,setLoggedIn,loading,setLoading}}>
     {loading &&<> <Puff   
                          height="80"
                          width="80"
                          radius={1}
                          color="#05A66B"
                          ariaLabel="puff-loading"
                            wrapperStyle={{
                              'position':'fixed',
                              'top':'50%',
                              'left':'50%',
                              'transform':'translate(-50%, -50%)',
                              'zIndex':'999'
                          }}
                          wrapperClass=""
                          visible={true}
                          

                          />

      <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(2px)',
            zIndex: 99,
      }}/> </>}
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <div><Toaster/></div>
      <StaySearchContext.Provider value={{
        value,
        setValue,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        guestAdultsInputValue,
        setGuestAdultsInputValue,
        guestChildrenInputValue,
        setGuestChildrenInputValue,
        guestInfantsInputValue,
        setGuestInfantsInputValue,
      }}>
        <PackageDataContext.Provider value={{
          packageData, setPackageData
        }}>
          <UserContext.Provider value={{ userData, setUserData }}>
        <MyRouter />  
        </UserContext.Provider>
        </PackageDataContext.Provider>
        </StaySearchContext.Provider>
          
      </div>
    
    </AuthContext.Provider>
  );
}
// exported
export default App;
