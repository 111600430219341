import React, { Fragment, useContext } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceDataContext } from '../PayPage';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60
    }
});

const InvoiceNo = ({invoiceId}:{invoiceId:string}) => {
    
    const {invoiceData}=useContext(InvoiceDataContext)

    return  (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Invoice No: </Text>
            <Text style={styles.invoiceDate}><br />{invoiceId.slice(0,8)}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date: </Text>
            <Text >{(JSON.stringify(new Date ())).slice(1,11)}</Text>
        </View >
    </Fragment>
);
    }

export default InvoiceNo;