import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import CarCard, { CouponData, emptyCouponData } from "components/CarCard/CarCard";
import AuthContext from "contexts/AuthContext";
import { UserContext } from "App";
import axios from "axios";
import { API_URL } from "services/constants";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const AccountCoupons = () => {
  const {setLoading,isLoggedIn} = useContext(AuthContext)
  const {userData,setUserData}=useContext(UserContext)
  let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  const [CouponData,setCouponData] = useState<CouponData[]>([emptyCouponData])
  let api = createApiInstance(authTokens.accessToken);
  useEffect(()=>{
    setLoading(true);
    api.get('booking/coupons/').then((response)=>{
      setCouponData(response.data)
      setLoading(false);

    }).catch(()=>setLoading(false));
  },[])
  return (
    <div>
      <CommonLayout>
      <div className="space-y-6 sm:space-y-8 text-center items-center">
      {CouponData.length>1 && <h2 className="text-3xl font-semibold">Special Offers</h2>}
        {CouponData.length<1 &&       <h2 className="text-3xl font-semibold">You dont have any coupons!</h2>}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {CouponData.map((value)=>{
      return <CarCard data={value.coupon} key={value.coupon.id}/>
      })}
   
                {
                    CouponData.length<1 && <>
                    <div className="flex mt-11 justify-center items-center">
                    <Badge color="green" name="Book packages on Enroute to get more coupons  " />
                    </div></>
                  }
        <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary href={'/listing-stay'}>Explore more packages for more coupons</ButtonSecondary>
                </div>

                  
      </div>
      </div>
      </CommonLayout>
    </div>
  );
};

export default AccountCoupons;
