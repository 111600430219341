import React from "react";
import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
//

import App from "./App";
import reportWebVitals from "./reportWebVitals";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  
  <GoogleOAuthProvider clientId="554605755287-d8roqboc9bhshhtitmb60pc225f3rsrl.apps.googleusercontent.com">
  <App />
  </GoogleOAuthProvider>,

  /* </React.StrictMode> */
);


reportWebVitals();
