import React, { Fragment, useContext } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ClientInformation, InvoiceDataContext } from '../PayPage';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});
interface Items {

}
const InvoiceTableRow = ({tableData}:{tableData:ClientInformation}) => {
    const {invoiceData}=useContext(InvoiceDataContext)
    return (<Fragment>
        <View style={styles.row} >
            <Text style={styles.description}>Adults</Text>
            <Text style={styles.qty}>{tableData.adults}</Text>
            <Text style={styles.rate}>{tableData.price}</Text>
            <Text style={styles.amount}>{(tableData.price*tableData.adults).toFixed(2)}</Text>
        </View>
        <View style={styles.row} >
            <Text style={styles.description}>Children</Text>
            <Text style={styles.qty}>{tableData.children}</Text>
            <Text style={styles.rate}>{tableData.price/2}</Text>
            <Text style={styles.amount}>{(tableData.price/2*tableData.children).toFixed(2)}</Text>
        </View>
        <View style={styles.row} >
            <Text style={styles.description}>Infants</Text>
            <Text style={styles.qty}>{tableData.infants}</Text>
            <Text style={styles.rate}>{invoiceData.packageDetail.price}</Text>
            <Text style={styles.amount}>Nil</Text>
        </View>
        {tableData.coupon_discount>0&&
        <View style={styles.row} >
            <Text style={styles.description}>Coupon Discount</Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}></Text>
            <Text style={styles.amount}>{tableData.coupon_discount}</Text>
        </View>
        }
    </Fragment>)
};

export default InvoiceTableRow;