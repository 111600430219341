import axios from "./UnAuthAxiosInstance";

const forgotPass = (email)=>
    axios.post('users/forgot_pass', {email: email})



export const sendOtp= (email,otp)=>
    axios.post('users/forgot_pass_otp',{otp: otp,email: email})

export const changePassword=(password,token)=>
    axios.post('users/forgot_change_password',{password: password,token: token})


export default forgotPass;

