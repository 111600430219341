import React, { FC, useState } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useLocation } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface Coupon {
  id: number;
  is_valid: boolean;
  code: string;
  coupon_name: string;
  is_active: boolean;
  discount_price: number;
  expiration_date: string;

}

export interface CouponData {
  coupon: Coupon;
  assigned_at: string;
}



export interface CarCardProps {
  className?: string;
  data?: Coupon;
  size?: "default" | "small";
  price?: number;
  setPrice?: React.Dispatch<React.SetStateAction<number>>;

}

export const emptyCoupon: Coupon = {
  id: 0,
  is_valid: false,
  code: '',
  coupon_name: '',
  is_active: false,
  discount_price:0 ,
  expiration_date: ''
};

export const emptyCouponData: CouponData = {
  coupon: emptyCoupon,
  assigned_at: ''
};



const CarCard: FC<CarCardProps> = ({
  size = "default",
  className = "",
  data = emptyCoupon,
  price = 0,
  setPrice=()=>{}
}) => {
  const specificDate = new Date(Date.parse(data.expiration_date));
  const today = new Date();
  const location = useLocation();
  const differenceInMs = specificDate.getTime() - today.getTime();
  const differenceInDays = Math.floor(differenceInMs / (24 * 60 * 60 * 1000));
  const [active,setActive] = useState(false)
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
{/* 
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
     <SaleOffBadge className="absolute left-3 top-3"  />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
         
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className="line-clamp-1">{data.coupon_name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">Flat discount for next booking</span>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base text-teal-700 font-semibold">
           -₹{data.discount_price}*
            {`   `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                 for any packages
              </span>
            )}
          </span>
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
          
         { data.is_valid &&<Badge name={`Expires in ${differenceInDays} days`} color="yellow"/>}
         { !data.is_valid &&<Badge name={`Coupon expired`} color="red"/>}
        </div>
        {(location.pathname === '/checkout' || location.pathname === '/listing-experiences-detail') &&!active&&
        <div>
        <ButtonPrimary className="justify-center items-center " onClick={()=>{
          setActive(true);
          setPrice(data.discount_price)
        }}>Apply</ButtonPrimary>
        </div>
        }
                {(location.pathname === '/checkout' || location.pathname === '/listing-experiences-detail') &&active&&
        <div>
        <ButtonPrimary className="justify-center items-center " disabled>Applied</ButtonPrimary>
        </div>
        }
        
      </div>
      
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >

        {renderContent()}
        
    </div>
  );
};

export default CarCard;
