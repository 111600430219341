import axios from "./UnAuthAxiosInstance";


const register = (email,password) => axios.post('users/register/',
    {
        email,
        password
    }
)

export default register
