import React, { FC,useContext,useEffect,useState} from "react";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link,Navigate,useNavigate} from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import signup from '../../services/signup'
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import { Console } from "console";
import otpSubmit from "services/otpsubmit";
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from "services/socialAuth";
import { UserContext, useAuthContext } from "App";
import toast from "react-hot-toast";
import { totalmem } from "os";
import axios from "axios";
import AuthContext from "contexts/AuthContext";
import { API_URL } from "services/constants";


export interface PageSignUpProps {
  className?: string;
}




const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,'Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'),''], 'Confirm Password does not match'),
});

type UserSubmitForm = {
  email: string;
  password: string;
  confirmPassword: string;
};

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  
const {isLoggedIn,setLoggedIn,setLoading,loading}=useAuthContext();
const [email,setEmail]=useState('');
const [otpSent,setOtpsent]= useState(false);
const [message,setMessage]=useState('');
const [success,setSuccess]=useState(true);
const [password,setPassword]=useState('');

const {userData,setUserData}=useContext(UserContext)
  const onSubmit = (data: UserSubmitForm) => {
    setLoading(true);
    signup(data.email, data.password).then((response) => {
      setLoading(false)
      setOtpsent(true);
      setEmail(data.email);
      setPassword(data.password);
      setSuccess(true);
      toast.success('Registered')
      setMessage('Registration successful🤩 Check your email and enter OTP below😊')
    }).catch((error) => {
      setLoading(false)
      setSuccess(false);
      setMessage(`${error.response.data.email?error.response.data.email:''}`)
      
      error.response.status==401 ? setOtpsent(true) : setOtpsent(false)
      toast.error(`${message}`)
    })
  };

type OTPForm = {
  otp: number
}

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

 const handleOtpSubmit = (e:any)=>{
    e.preventDefault()
    let otp:number = e.target[0].value
    otpSubmit(email,password,otp).then((response)=>{
      setSuccess(true);
      toast.success('OTP Submit Success')
      let authTokens = {
        'accessToken': response.data?.access_token,
        'refreshToken': response.data?.refresh_token,
        'exp':response.data?.expires_in
    }
    localStorage.setItem('authTokens', JSON.stringify(authTokens));

      setLoggedIn(true);
    }).catch((error)=>{
      setSuccess(false);
      setMessage('Please check your OTP again!')
    })

  }
  const navigate = useNavigate();
  const onSuccessGoogle  = (response:any)=>{
      googleLogin(response.access_token)
      toast.success('You are logged in ')
      setLoggedIn(true);
      
      const checkoutRedirect = sessionStorage.getItem("checkoutRedirect")

      const createApiInstance = (accessToken:string) => {
        return axios.create({
          baseURL: API_URL,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      };
      let authTokens = localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens')!)
      : { accessToken: '', refreshToken: '', exp: '' };
      let api = createApiInstance(authTokens.accessToken);
      api.get('users/user_profile/').then((response)=>{
        setLoading(false)
        setUserData(response.data)
        
      }).catch((error:any)=>{
        setLoading(false)
      })
      if (isLoggedIn && checkoutRedirect) {
        // Redirect user back to checkout page
        navigate(checkoutRedirect)
        // Clear stored URL from session storage
        sessionStorage.removeItem("checkoutRedirect");
      }else{
        navigate('/')
      }
  }
  const onErrorGoogle = (response:any)=>{
    toast.error('Uhh Oh! Please use another Account')
  }
  const loginGoogle = useGoogleLogin({
    onSuccess: onSuccessGoogle,
    onError: onErrorGoogle,
  });

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      
      {isLoggedIn&& <Navigate replace to='/account'/>}
      <Helmet>
        <title>Sign up || Enroute</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">

              {/* <button
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src = {facebookSvg}
                  alt='Continue with Facebook'
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  Continue with Facebook
                </h3> 
              </button> */}

              <button onClick={()=>loginGoogle()}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src = {googleSvg}
                  alt='Continue with Google'
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  Continue with Google
                </h3> 
              </button>

          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6"  onSubmit={handleSubmit(onSubmit)}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                {...register('email')}
                
              />
              <div className="text-red-500 text-xs">{errors.email?.message}</div>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" 
              className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
              {...register('password')}/>
                     <div className="text-red-500 text-xs mt-2 mx-2">{errors.password?.message}</div>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" 
              className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
              {...register('confirmPassword')} />
              <div className="text-red-500 text-xs mt-2 mx-2">{errors.confirmPassword?.message}</div>
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
          {  message != ''?<Badge color={success?'green':'red'} name={`${message}`} className="items-center" />:<></>}
         
          {otpSent&&<>
            {/* <h1>Yay! You have joined our community of Travel enthusiasts!. </h1> */}
           
        
            <form className="mt-10 relative max-w-sm" onSubmit={handleOtpSubmit}>
          <Input
            required
            aria-required
            placeholder="Enter OTP"
            type="number"
            rounded="rounded-full"
            name="otp"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
                    </>}

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
