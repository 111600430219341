import Label from "components/Label/Label";
import React, { FC, useContext, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import axios from "axios";
import AuthContext from "contexts/AuthContext";
import { API_URL } from "services/constants";
import { UserContext } from "App";
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { blob } from "stream/consumers";
import { toast } from "react-hot-toast";
export interface AccountPageProps {
  className?: string;
}
export interface UserData {
  address:string ;
  first_name:string ;
  last_name:string ;
  email:string;
  gender:string ;
  phone_number:string ;
  profile_image_url:string ;
}
const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const {setLoading,isLoggedIn} = useContext(AuthContext)
const {userData,setUserData}=useContext(UserContext)
let authTokens = localStorage.getItem('authTokens')
? JSON.parse(localStorage.getItem('authTokens')!)
: { accessToken: '', refreshToken: '', exp: '' };
const createApiInstance = (accessToken:string) => {
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

let api = createApiInstance(authTokens.accessToken);
const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Please add a first name')
    .min(2, 'First Name should contain at least 6 characters'),
  last_name: Yup.string()
    .required('Please add a last name')
    .min(2, 'Last Name should contain at least 6 characters'),
  phone_number: Yup.string()
  .required('Please add a phone number')
  .length(10,'Phone number should be 10 characters')
  .matches(/^[789]\d{9}$/,'Please type a valid phone number'),
  gender: Yup.string().notRequired(),

  address:Yup.string()
  .required('Please add an address')
});

  useEffect(()=>{

    setLoading(true)

    api.get('users/user_profile/').then((response)=>{
      setLoading(false)
      setUserData(response.data)
      
    }).catch((error:any)=>{
      setLoading(false)
    })

  },[isLoggedIn])
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserData>({
    resolver: yupResolver(validationSchema)
  });
  
  useEffect(()=>{

  },[userData,isLoggedIn])

  const profileSubmit= (data:UserData)=>{

    setLoading(true)
    api.put('users/user_profile/',data=data).then((response)=>{
      setLoading(false)
      toast.success('Profile updated successfully')
    }).catch((error)=>{
      console.error(error)
      toast.error('Please try again')
    })
  }

  const handleFileChange = (e:any) => {

    let form_data = new FormData();
    e.target.files[0] && form_data.append('profile_image',e.target.files[0],e.target.files[0].name);
  api.put('users/user_profile/', form_data)
  .then((response) => {
    setUserData(response.data)
  })
  .catch((error) => {
setLoading(false)
  });
    
}

  return (
    
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
    
      <Helmet>
        <title>Account || Enroute</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <form onSubmit={handleSubmit(profileSubmit)}>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                {userData?.profile_image_url&&<Avatar sizeClass="w-32 h-32" imgUrl={`${userData.profile_image_url}`} />}
         
                {!userData?.profile_image_url&&<Avatar sizeClass="w-32 h-32" />}
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>

                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleFileChange}
                  name="profile_image"
                />

              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>First Name</Label>
                <Input {...register('first_name')} className={`mt-1 ${errors.first_name ? 'border border-red-500' : ''}`} defaultValue={`${userData?.first_name}`} name="first_name" />
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.first_name?.message}</div>
              </div>
              <div>
                <Label>Last Name</Label>
                <Input   className={`mt-1 ${errors.last_name ? 'border border-red-500' : ''}`} defaultValue={`${userData?.last_name}`} {...register('last_name')}/>
              </div>
              <div>
              <Label>Gender</Label>
                <Select {...register('gender')} 
                
                className={`mt-1 ${errors.gender ? 'border border-red-500' : ''}`} value={`${userData?.gender}`} onChange={(e) =>{ setUserData({...userData, gender: e.target.value})
      
                }}>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </Select>
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.gender?.message}</div>
              </div>
              {/* ---- */}

              {/* ---- */}
              <div>
                <Label>Email</Label>
                <Input  className='mt-1' defaultValue={`${userData?.email}`} disabled readOnly/>
            
              </div>
              {/* ---- */}
              {/* <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  defaultValue="1990-07-22"
                />
              </div> */}
              {/* ---- */}
              <div>
                <Label>Addess</Label>
                <Input {...register('address')} className={`mt-1 ${errors.address ? 'border border-red-500' : ''}`}defaultValue={`${userData?.address?userData.address:''}`} />
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.address?.message}</div>
              </div>

              {/* ---- */}
              <div>
                <Label>Phone number</Label>
                <Input {...register('phone_number')}  className={`mt-1 ${errors.phone_number ? 'border border-red-500' : ''}`} defaultValue={`${userData?.phone_number?userData?.phone_number:''}`}  />
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.phone_number?.message}</div>
              </div>

              {/* ---- */}
              {/* <div>
                <Label>About you</Label>
                <Textarea className="mt-1.5" defaultValue="..." />
              </div> */}
              <div className="pt-2">
                <ButtonPrimary type="submit" > Update info</ButtonPrimary>
              </div>
            </div>
          </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
