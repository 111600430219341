import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import AuthContext from "contexts/AuthContext";
import { UserContext } from "App";
import axios from "axios";
import { API_URL } from "services/constants";
import Pagination from "shared/Pagination/Pagination";
import Paginationss from "./Paginationss";
import CancelModal from "./CancelModal";


export interface Booking {
  id: number;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    gender: string;
    address: string;
    phone_number: string;
    profile_image_url: string;
  };
  payment: {
    amount: number;
    status: string;
  };

  booking_code: string;
  date_booked: string;
  start_date: string;
  end_date: string;
  num_adults: number;
  num_infants: number;
  num_children: number;
  phone_number: string;
  status: string;
  package: {
    category: string;
    duration: string;
    type: string;
    main_place: string;
    name: string;
    id:number;
    price:number;
    discount_price:number;
  };
  coupon:  {
    discount_price: number;
  };
}

const AccountBilling = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const {setLoading,isLoggedIn} = useContext(AuthContext)
  const [reload,setReload]=useState(false)
  const [totalPages,setTotalPages] = useState(1)
  const [currentPage,setCurrentPage]= useState(1)
  const {userData,setUserData}=useContext(UserContext)
  let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  useEffect(()=>{
    setLoading(true)
    let api = createApiInstance(authTokens.accessToken);
    api.get(`users/booking_detail/${currentPage?`?page=${currentPage}`:''}`).then((res)=>{
      setBookings(res.data.results)
      setTotalPages(Math.ceil(res.data.count/4))
      setLoading(false)

    }).catch(()=>{
      setLoading(false)
    })
  },[currentPage,reload])
  return (
    <div>
         
      <CommonLayout>
      <div className="space-y-6 sm:space-y-8">
      <h2 className="text-3xl font-semibold text-center">Your bookings</h2>
      {bookings.map((data, index) => (
  <React.Fragment key={data.id}>
    
    <CancelModal

      package_id={data.package.id}
      name={data.package.name}
      key={`cancel-modal-${index}`}
      data={data}
      setReload={setReload}
      reload={reload}
    />
  </React.Fragment>
))}
      

      <div className="cursor-pointer w-full " style={{ display: "flex", justifyContent: "center" }}>
         <Paginationss pageCount={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
         </div>
      </div>
 
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
