import React, { useContext, useEffect, useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { StaySearchContext } from "App";
import UnAuthAxiosInstance from "services/UnAuthAxiosInstance";
import qs from "qs";
import { useLocation } from "react-router-dom";

const MobileFooterSticky = (props:any) => {
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [price,setPrice] = useState(0)
  
  const [duration,setDuration] = useState('')
  useEffect(()=>{
   UnAuthAxiosInstance.get(`packages/package_detail/${searchParams.id}/`).then((res)=>{
    let price = res.data.packageData.discount_price?res.data.packageData.discount_price:res.data.packageData.price
    setPrice(price)
    setDuration(res.data.packageData.duration)
    
   })
  },[])
  const { startDate ,endDate,setEndDate} = useContext(StaySearchContext);
 
  
  // const getDurationDays = (duration: string) => {
  //   const match = duration.match(/^\d+/);
  //   const days = match ? parseInt(match[0]) : undefined;
  //   return days;
  // };; 
  // const durationDays = getDurationDays(duration); 
  
  // if (durationDays && startDate instanceof Date) {
  // let  newendDate = new Date(startDate); 
  //  newendDate.setDate(startDate.getDate() + durationDays); 
  //  setEndDate(newendDate);
  // }
  
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
          {price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /PERSON
            </span>
          </span>
          <ModalSelectDate
            duration={duration}
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString([startDate, endDate])}
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={openModal}
            >
              Book
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
