import React, { FC, useState,useContext } from "react";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import login from '../../services/login'
import toast, { Toaster } from 'react-hot-toast';
import Badge from "shared/Badge/Badge";
import { UserContext, useAuthContext } from "App";
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from "services/socialAuth";
import AuthContext from "contexts/AuthContext";
import axios from "axios";
import { API_URL } from "services/constants";
export interface PageLoginProps {
  className?: string;
}






const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,'Email is invalid'),
    
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
});




  type UserSubmitForm = {
    email: string;
    password: string;
  };
  const [email,setEmail]=useState('');
  const [reqSent,setreqsent]= useState(false);
  const [message,setMessage]=useState('');
  const [success,setSuccess]=useState(true);
  const [password,setPassword]=useState('');
  const navigate = useNavigate();
  const {isLoggedIn,setLoggedIn,setLoading}= useAuthContext()

  const {userData,setUserData}=useContext(UserContext)
  const onSubmit = (data: UserSubmitForm) => {
    setLoading(true);
    login(data.email, data.password).then(() => {
      setSuccess(true);
      const checkoutRedirect = sessionStorage.getItem("checkoutRedirect")

const createApiInstance = (accessToken:string) => {
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
let authTokens = localStorage.getItem('authTokens')
? JSON.parse(localStorage.getItem('authTokens')!)
: { accessToken: '', refreshToken: '', exp: '' };
let api = createApiInstance(authTokens.accessToken);
api.get('users/user_profile/').then((response)=>{
  setLoading(false)
  setUserData(response.data)
  
}).catch((error:any)=>{
  setLoading(false)
})
      if (checkoutRedirect) {
        // Redirect user back to checkout page
        navigate(checkoutRedirect)
        // Clear stored URL from session storage
        sessionStorage.removeItem("checkoutRedirect");
      }else{
        navigate('/')
      }
      setLoading(false)
      setLoggedIn(true);
      toast.success('Login successful')
    }).catch((response) => {
      setSuccess(false);
      setLoading(false)
      setMessage(response.response.data.error_description) 
      setreqsent(true)  
      toast.error('Login failed. pLease check the credentials')
    })
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });
  

 
  const onSuccessGoogle  = (response:any)=>{
      googleLogin(response.access_token)
      setLoggedIn(true);
      toast.success('Login successful');
      const checkoutRedirect = sessionStorage.getItem("checkoutRedirect")

const createApiInstance = (accessToken:string) => {
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
let authTokens = localStorage.getItem('authTokens')
? JSON.parse(localStorage.getItem('authTokens')!)
: { accessToken: '', refreshToken: '', exp: '' };
let api = createApiInstance(authTokens.accessToken);
api.get('users/user_profile/').then((response)=>{
  setLoading(false)
  setUserData(response.data)
  
}).catch((error:any)=>{
  setLoading(false)
})
      if ( checkoutRedirect) {
        // Redirect user back to checkout page
        navigate(checkoutRedirect)
        // Clear stored URL from session storage
        sessionStorage.removeItem("checkoutRedirect");
      }else{
        navigate('/')
      }

  }
  const onErrorGoogle = (response:any)=>{

  }
  const loginGoogle = useGoogleLogin({
    onSuccess: onSuccessGoogle,
    onError: onErrorGoogle,
  });
  return (
    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      {isLoggedIn&& <Navigate replace to='/account'/>}
      <Helmet>
        <title>Login || Enroute Travel</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
          <button onClick={()=>loginGoogle()}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src = {googleSvg}
                  alt='Continue with Google'
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  Continue with Google
                </h3> 
              </button>

          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                {...register('email')}
                name='email'
                onChange={()=>{setreqsent(false);setMessage('')}}
              />
                  <div className="text-red-500 text-xs mt-2 mx-2">{errors.email?.message}</div>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" 
                      className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                      {...register('password')}
                      name='password'
                      onChange={()=>{setreqsent(false);setMessage('')}}
               />
               <div className="text-red-500 text-xs mt-2 mx-2">{errors.password?.message}</div>
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
          {reqSent &&
          //  <Badge color='red' name={`${message}`} className="items-center" />
          <></>
          }
          
          
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
