import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard, { dummyData } from "components/StayCard/StayCard";
import axios from "services/UnAuthAxiosInstance";
import { useAuthContext } from "App";
import { PackageData } from "data/types";
import { log } from "console";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: PackageData[] =[dummyData]

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: PackageData[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}



const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured Enroute Packages ",
  subHeading = "Popular places to stay that Enroute Travel recommends for you",
  headingIsCenter,
  tabs = [],
}) => {
  const [packages,setPackages] = useState([])
  const [mainPlace,setMainPlace] = useState([])
  const {setLoading}  =useAuthContext()
  const [mainLocation,setMainLocation] = useState<string>('')

  
  useEffect(()=>{
    setLoading(true)
    axios.get(`home/featured/`,{params:{
      main_place:mainLocation
    }}).then((res)=>{
      setMainPlace(res.data.main_places)
      let packageData = res.data.packages.map((item:any)=>({
        id: item.id,
        packageName:item.name,
        duration:item.duration,
        averageRating:item.average_rating,
        totalReviews:item.total_reviews,
        category:item.category,
        discount:item.discount_percent,
        price:item.price,
        type:item.type,
        images:item.images.map((image:any) => image.image_url)
      }))

      setPackages(packageData)
      setMainLocation(res.data.main_places[0])   
      setLoading(false)
    }).catch(()=>{
      setLoading(false)
    })
  },[])
  const fetchData = (location: string) => {
    setLoading(true);
    axios
      .get(`home/featured/`, {
        params: {
          main_place: location,
        },
      })
      .then((res) => {
        setMainPlace(res.data.main_places);
        let packageData = res.data.packages.map((item: any) => ({
          id: item.id,
          packageName: item.name,
          duration: item.duration,
          averageRating: item.average_rating,
          totalReviews: item.total_reviews,
          category: item.category,
          discount: item.discount_percent,
          price: item.price,
          type: item.type,
          images: item.images.map((image: any) => image.image_url),
        }));
  
        setPackages(packageData);
        setLoading(false);
      }).catch(()=>{
        setLoading(false)
      });
  };
  
  useEffect(() => {
    fetchData(mainLocation);
  }, [mainLocation]);

  useEffect(()=>{
  },[packages,mainPlace,mainLocation])
  const renderCard = (stay: PackageData) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={mainLocation}
        subHeading={subHeading}
        tabs={mainPlace.slice(0,8)}
        heading={heading}
        onClickTab={() => {}}
        setMainLocation={setMainLocation}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {packages.map((stay) => renderCard(stay))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary href={'/listing-stay'}>Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
