import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Avatar from "shared/Avatar/Avatar";
import { Booking } from "containers/AccountPage/AccountBilling";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { InvoiceData } from "containers/PayPage/PayPage";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "containers/PayPage/invoice";
import { string } from "yup";

export interface ExperiencesCardHProps {
  className?: string;
  data?: Booking;
  setShowModal?:React.Dispatch<React.SetStateAction<boolean>>;
}

const emptyBooking: Booking = {
  id: 0,
  user: {
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
    address: "",
    phone_number: "",
    profile_image_url: "",
  },
  payment: {
    amount: 0,
    status: "",
  },
  booking_code: "",
  date_booked: "",
  start_date: "",
  end_date: "",
  num_adults: 0,
  num_infants: 0,
  num_children: 0,
  phone_number: "",
  status:'',
  package: {
    category: "",
    duration: "",
    type: "",
    main_place: "",
    name: "",
    id:0,
    price:0,
    discount_price:0,
    
  },
  coupon: {
    discount_price:0
  },
};


const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data = emptyBooking,
  setShowModal
}) => {
  const [invoiceData,setInvoiceData]= useState<InvoiceData>(   {
    invoiceId: "",
    user: {
      userName: "",
      email: "",
      phoneNumber: "",
    },
    packageDetail: {
      packageName: "",
      packagePlace: "",
      price: 0,
    },
    clientInformation: {
      adults: 0,
      children: 0,
      infants: 0,
      price: 0,
      coupon_discount: 0,
    },
  })
useEffect(()=>{
  let Data = {
    invoiceId:data.booking_code,
    user:{
      userName:data.user.first_name+' '+data.user.last_name,
      email:data.user.email,
      phoneNumber:data.user.phone_number
    },
    packageDetail:{
      packageName:data.package.name,
      packagePlace:data.package.main_place,
      price:data.package.discount_price>0?data.package.discount_price:data.package.price

    },
    clientInformation:{
      adults:data.num_adults,
      children:data.num_children,
      infants:data.num_infants,
      price:data.package.discount_price>0?data.package.discount_price:data.package.price,
      coupon_discount:data.coupon?data.coupon.discount_price:0
    }


  }
  setInvoiceData(Data)
},[])





  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col items-center">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <Link to={`/listing-experiences-detail?id=${data.package.id}`}>
            <h2 className="text-lg font-bold capitalize">
              <span className="line-clamp-1">{data.package.name}</span>
            </h2>
            </Link>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
     <h4>{data.package.category}</h4>
            <span>· </span>
            <div className="flex items-center">
              <span className="hidden sm:inline-block  text-base">
                <i className="las la-map-marked"></i>
              </span>
              <span className="sm:ml-2"> {data.package.main_place}</span>
            </div>
          </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div> */}

        <div className="flex items-center space-x-8 mt-4  ">
          <div className="flex items-center space-x-2">
            <i className="las la-clock text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {data.package.duration}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data.num_adults+data.num_children} Adults & {data.num_infants} Infants
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-8 mt-4  ">
          <div className="flex items-center space-x-2">
            <i className="las la-calendar text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {data.start_date}
            </span>
          </div>
        </div>
        {/* <div>


        <h5 className="text-sm  capitalize text-neutral-500 dark:text-neutral-400 mb-0">
              <span className="line-clamp-1">Booked on - </span> 
            </h5>

            <div >
           <span className="text-sm text-neutral-500 dark:text-neutral-400 "> {data.date_booked.slice(0,10)}</span>
            </div>

        </div> */}
        <Badge className="mt-3" name={data.status} color={data.status=='Success'?'green':'red'} />
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
        <div className="flex justify-between items-end">

          <span className="text-base text-secondary-700">
          Paid amount :
            {` `}
            <span className="font-semibold  text-neutral-500 dark:text-neutral-400 font-normal">
            {(data.payment.amount).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}
            </span>
          </span>
        </div>
        {data.status=='Success'&&
      <div className="flex flex-row mt-5">
        <ButtonPrimary className="px-3 py-2 sm:px-6">    <PDFDownloadLink
          document={<PdfDocument invoiceData={invoiceData} />}
          fileName='Booking invoice'
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Download Invoice"
          }
        </PDFDownloadLink></ButtonPrimary>
        <ButtonSecondary className="mx-3" onClick={()=>{
          setShowModal&&setShowModal(true);
        }}>Cancel Booking</ButtonSecondary>
      </div>
      }
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="ExperiencesCardH"
    >
      {/* <Link to={href} className="absolute inset-0" /> */}
      <div className="md:flex md:flex-row">
        {/* {renderSliderGallery()} */}
        {renderContent()}
 
      </div>
    </div>
  );
};

export default ExperiencesCardH;
