import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { StaySearchContext, UserContext } from "App";
import { PackageDataContext } from "contexts/packageDataContext";
import AuthContext from "contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import CarCard, { emptyCouponData,CouponData } from "components/CarCard/CarCard";
import { API_URL } from "services/constants";
import axios from "axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import Badge from "shared/Badge/Badge";
import { razor } from "services/env";
import toast from "react-hot-toast";
export interface CheckOutPagePageMainProps {
  className?: string;
}
declare global {
  interface Window {
    Razorpay: any;
  }
}
interface UserData {
  firstName:string ;
  lastName:string ;
  phoneNumber:string ;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const {setLoading} = useContext(AuthContext)
const {userData,setUserData}=useContext(UserContext)
let authTokens = localStorage.getItem('authTokens')
? JSON.parse(localStorage.getItem('authTokens')!)
: { accessToken: '', refreshToken: '', exp: '' };
const createApiInstance = (accessToken:string) => {
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const [CouponData,setCouponData] = useState<CouponData[]>([emptyCouponData])
let api = createApiInstance(authTokens.accessToken);
const [phoneNumber,setPhoneNumber] = useState(userData.phone_number)
const [firstName,setFirstName] = useState(userData.first_name)
const [lastName,setLastName] = useState(userData.last_name)

const {startDate,endDate,guestInfantsInputValue,guestAdultsInputValue,guestChildrenInputValue,setEndDate} = useContext(StaySearchContext)
const {packageData}  =useContext(PackageDataContext)
const {isLoggedIn}  =useContext(AuthContext)
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Please add a first name')
    .min(2, 'First Name should contain at least 6 characters'),
  lastName: Yup.string()
    .required('Please add a last name')
    .min(2, 'Last Name should contain at least 6 characters'),
  phoneNumber: Yup.string()
  .required('Please add a phone number')
  .length(10,'Phone number should be 10 characters')
  .matches(/^[789]\d{9}$/,'Please type a valid phone number'),

});
const navigate = useNavigate()
!isLoggedIn&&navigate('/login')
useEffect(()=>{
  const getDurationDays = (duration: string) => {
    const match = duration.match(/^\d+/);
    const days = match ? parseInt(match[0]) : undefined;
    return days;
  };
  const durationDays = getDurationDays(packageData.duration);
  if (durationDays !== undefined) {
    const endDate = startDate&&new Date(startDate.getTime() + durationDays * 24 * 60 * 60 * 1000);
    setEndDate(endDate);

  } else {
    setEndDate(null);
  }  
},[])

useEffect(()=>{
  setLoading(true);
  api.get('booking/coupons/').then((response)=>{
    const filteredArray = response.data.filter((obj:any)=> obj.coupon.is_valid === true);
    setCouponData(filteredArray)
    setLoading(false);


  }).catch((err)=>{
    setLoading(false);
  })
},[])
let price = packageData.discount_price?packageData.discount_price:packageData.price
const [offPrice,setOffPrice]= useState(0)
const {
  register,
  handleSubmit,
  formState: { errors }
} = useForm<UserData>({
  resolver: yupResolver(validationSchema)
});


const [isApplied,setIsApplied]=useState<boolean>(false)

let total_price = (guestAdultsInputValue+(guestChildrenInputValue/2))*price
let total_price_after_reduction  = total_price - offPrice
const handleSuccess = (res:any)=>{
  setLoading(true)

  let coupon = offPrice>0?CouponData[0].coupon.id:null
  let response = {
    'res':res,
    'adults':guestAdultsInputValue,
    'children':guestChildrenInputValue,
    'infants':guestInfantsInputValue,
    'phone_number':phoneNumber!=''?phoneNumber:userData.phone_number,
    'package':packageData.id,
    'start_date':startDate,
    'end_date':endDate,
    'coupon_id':coupon
  }

  api.post(`${API_URL}booking/razorpay_callback/`,response).then((response)=>{
    toast.success('Booking successful')
    navigate('/pay-done',{state:response.data})

  }).catch((err)=>{
    toast.error('something happened')

  })
}
function loadRazorpayScript(src:any) {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

//function will get called when clicked on the pay button.
async function displayRazorpayPaymentSdk() {
  setLoading(true);

  const res = await loadRazorpayScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
    toast.error("Razorpay SDK failed to load. please check are you online?");
    setLoading(false);
    return;
  }

  try {
    // creating a new order and sending order ID `to backend
    setLoading(true);
    const result = await api.post(`${API_URL}booking/razorpay_booking/`, {
      amount: total_price_after_reduction / 2,
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
    });

    if (!result) {
      toast.error("Server error. please check are you online?");
      return;
    }

    setLoading(false);

    // Getting the order details back
    const { merchantId = null, amount = null, currency = null, orderId = null } =
      result.data;

    const options = {
      key_id: merchantId,
      key_secret: razor,
      amount: amount,
      currency: currency,
      name: "Enroute Travel",
      description: `Advance amount for ${packageData.name}`,
      image: 'https://res.cloudinary.com/dg047twga/image/upload/v1681901623/hhdzmojhkp7kpwjtjhtk.jpg',
      order_id: orderId,
      handler: (res: any) => handleSuccess(res),
      redirect: true,
      prefill: {
        name: `${firstName} ${lastName}`,
        email: userData.email,
        contact: phoneNumber,
      },
      notes: {
        address: "None",
      },
      theme: {
        color: "#115E59",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } catch (error) {
    toast.error ("Error occurred while processing the payment. Please try again.");
    setLoading(false);
  }
}

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={`${packageData.images[0].image_url}`}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {packageData.main_place}
              </span>
              <span className="text-base font-medium mt-1 block">
                {packageData.name}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {packageData.duration}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating point={packageData.average_rating?.toFixed(1)} reviewCount={packageData.total_reviews}/>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})} x {guestAdultsInputValue+guestChildrenInputValue} Guests</span>
            <span>{total_price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>

             {offPrice>0 &&
             <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="text-teal-500">*Coupon Discount</span>
            <span className="text-teal-500">-{(offPrice).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}</span>
          </div>}
                      {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>{(total_price*0.02
            ).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}</span>
          </div> */}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-bold">
            <span>Total</span>
            <span>{(total_price_after_reduction).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Booking Advance </span>
            <span>{(total_price_after_reduction/2).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate duration={packageData.duration}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([startDate, endDate])}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guestAdultsInputValue || 0) +
                          (guestChildrenInputValue || 0)
                        } Guests, ${guestInfantsInputValue || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
          </div>
        </div>

        <div>
         {CouponData.length>0 && <h4 className="text-1xl font-semibold">**Apply coupon for instant discount</h4>}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      {CouponData.slice(0,1).map((value)=>{

       return (
       <>
       {value.coupon.is_valid&&value.coupon.is_active&&
       <>
       <CarCard data={value.coupon} key={value.coupon.id} price = {offPrice} setPrice={setOffPrice} />
       </>
      }
       </>)
      })}
          
          </div>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <Badge name='Pay 50% Advance Now' color="indigo"></Badge>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
<></>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>First Name</Label>
                    <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                    <Input defaultValue={userData.first_name}
                        className={`mt-1 ${errors.firstName ? 'border border-red-500' : ''}`}
                        {...register('firstName')}
                    required onChange={(e)=>(
                      setFirstName(e.target.value)
                    )}/>
                     <div className="text-red-500 text-xs mt-2 mx-2">{errors.firstName?.message}</div>
                  </div>
                  <div className="space-y-1">
                    <Label>Last Name</Label>
                    <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                    <Input defaultValue={userData.last_name}
                        className={`mt-1 ${errors.lastName ? 'border border-red-500' : ''}`}
                        {...register('lastName')}
                    required onChange={(e)=>(
                      setLastName(e.target.value)
                    )}/>
                     <div className="text-red-500 text-xs mt-2 mx-2">{errors.lastName?.message}</div>
                  </div>

                  <div className="space-y-1">
                    <Label>Mobile Number</Label>
                    <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                    <Input defaultValue={userData.phone_number} 
                        className={`mt-1 ${errors.phoneNumber ? 'border border-red-500' : ''}`}
                        {...register('phoneNumber')}
                    required onChange={(e)=>(
                      setPhoneNumber(e.target.value)
                    )}/>
                     <div className="text-red-500 text-xs mt-2 mx-2">{errors.phoneNumber?.message}</div>
                    <span className="text-sm text-neutral-500 block">
                    </span>
                  </div>
                </Tab.Panel>

              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <span className="text-red-800 dark:text-red-500 ">**Please double-check to ensure all information is correct before proceeding with your booking</span><br /><br />
              <ButtonPrimary onClick={handleSubmit(displayRazorpayPaymentSdk)}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
