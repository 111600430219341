import axios from "./UnAuthAxiosInstance";

const submitOtp = (email,password,otp)=>
    axios.post('users/verify/',
    {
    email,
    password,
    otp
    }
    )

export default submitOtp