import React, { useContext, useEffect, useState } from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import axios from "axios";
import { API_URL } from "services/constants";
import AuthContext from "contexts/AuthContext";
import { toast } from "react-hot-toast";

// DEMO DATA
const POSTS = DEMO_POSTS;

export interface ExperienceType {
  id: number;
  traveler_name: string;
  traveler_image: string;
  date_published: string;
  experience_header: string;
  experience_sub_header: string;
  experience_main_image: string;
}
export let emptyExperience: ExperienceType = {
  id: 0,
  traveler_name: "",
  traveler_image: "",
  date_published: "",
  experience_header: "",
  experience_sub_header: "",
  experience_main_image: "",
};

// DEMO POST FOR MAGAZINE SECTION


const BlogPage: React.FC = () => {
  const [experiences, setExperiences] = useState<ExperienceType[]>([emptyExperience]);
  const {setLoading}  =useContext(AuthContext)
  useEffect(()=>{
    setLoading(true)
    axios.get(`${API_URL}experience/all_experiences/`).then((res)=>{
      setLoading(false);
      setExperiences(res.data)
    }).catch((err)=>{
      setLoading(false);
      toast.error('Uhh oh , Something went wrong')
    })
  },[])
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Experience  || Enroute Travel</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 posts={experiences} />
        </div>
        

        {/* === SECTION 1 === */}
        <SectionAds />

        {/* === SECTION 8 === */}
        <SectionLatestPosts className="py-16 lg:py-28" />

        {/* === SECTION 1 === */}
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default BlogPage;
