import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC, createContext, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import axios from "services/UnAuthAxiosInstance";
import { useAuthContext } from "App";
import { useLocation } from "react-router-dom";
import qs from "qs";
import toast from "react-hot-toast";


export interface ListingStayPageProps {
  className?: string;
}
export interface IListingStayContext {
  packages: any[];
  currentPage: number;
  setPackages: React.Dispatch<React.SetStateAction<never[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rangePrices: number[];
  setRangePrices: React.Dispatch<React.SetStateAction<number[]>>;
  uniqueTypes:string[];
  checkedBoxes: string[];
  setCheckedBoxes: React.Dispatch<React.SetStateAction<string[]>>;
}


export const ListingStayContext = createContext<IListingStayContext>({
  packages: [],
  currentPage: 1,
  setPackages: () => {},
  setCurrentPage: () => {},
  rangePrices: [0, 100000],
  setRangePrices: () => {},
  uniqueTypes: [],
  checkedBoxes: [],
  setCheckedBoxes: () => {},
});
const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
const [packages,setPackages] =useState([])
const [currentPage,setCurrentPage] = useState(1)
const [count,setCount]= useState<number>(0)
const {setLoading}= useAuthContext()
const [totalPages,setTotalPages] = useState(1)
const location = useLocation();
const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
const [uniqueTypes,setUniqueTypes] = useState<string[]>([])
const value = searchParams.value?searchParams.value:''
const [rangePrices, setRangePrices] = useState([0, 100000]);
const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);
const [price_min,price_max]  = rangePrices
  useEffect(()=>{
    setLoading(true)
    axios.get(`packages/package_search/${currentPage?`?page=${currentPage}`:''}`,{params:{
      value:value,
      price_max,
      price_min,
      type:JSON.stringify( checkedBoxes)
    
    }}).then((res)=>{
      setTotalPages(Math.ceil(res.data.count/8))
     
      let packageData = res.data.results.results.map((item:any)=>({
        id: item.id,
        packageName:item.name,
        duration:item.duration,
        averageRating:item.average_rating,
        totalReviews:item.total_reviews,
        category:item.category,
        discount:item.discount_percent,
        price:item.price,
        type:item.type,
        images:item.images.map((image:any) => image.image_url)
      }))
      // setUniqueTypes(res.data.results.uni)
      setUniqueTypes(res.data.results.unique_types)
      setPackages(packageData)
      setLoading(false)
      setCount(res.data.count)
    }).catch((error) => {
      toast.error(error.response.data.error)
      setLoading(false)
    })
  },[currentPage,value,rangePrices,checkedBoxes])


  return (
    <ListingStayContext.Provider
    value={{ packages, currentPage, setPackages, setCurrentPage,rangePrices,setRangePrices,uniqueTypes,checkedBoxes,setCheckedBoxes }}
    >
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Enroute Travel</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          count= {count}
          currentPage="Packages"
          currentTab="Packages"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-28" data={packages} pageCount={totalPages} />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          {/* <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          /> */}
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />

        {/* SECTION */}
        <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          {/* <SectionGridAuthorBox /> */}
        </div>
      </div>
    </div>
    </ListingStayContext.Provider>
  );

};

export default ListingStayPage;
