import React, { FC, SetStateAction } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { PackageData } from "data/types";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useAuthContext } from "App";
export interface StayCardProps {
  className?: string;
  data?: PackageData;
  size?: "default" | "small";
  setChange?:React.Dispatch<React.SetStateAction<boolean>>
}


export const dummyData: PackageData = {
  id: 0,
  packageName: "",
  duration: "",
  averageRating: 0,
  totalReviews: 0,
  category: "",
  discount: 0,
  price: 0,
  images: [],
  type: "",
};


const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = dummyData ,
  setChange
}) => {
  const {
    id,
    packageName,
    duration,
    averageRating,
    totalReviews,
    category,
    discount,
    images,
    price,
    type,

  } = data;
  
  const {isLoggedIn} = useAuthContext()
  const renderSliderGallery = () => {

    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={images}
          href={`/listing-experiences-detail?pacakageName=${packageName}&id=${id}`}
        />
       {isLoggedIn&& <BtnLikeIcon  className="absolute right-3 top-3 z-[1]" packageId={id} setChange ={setChange}/>}
        {discount!=0 && <SaleOffBadge className="absolute left-3 top-3" desc={`-${discount}% Exclusive discount`} />}
        {/* {isGroup==true && <SaleOffBadge className="absolute left-3 top-3" desc={`Fixed Group`} />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
        <div style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '1.3rem', height: '1.3rem' }}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span style={{ marginLeft: '0.5rem',marginRight:'0.5rem' }} className="text-sm text-neutral-500 dark:text-neutral-400">
      {duration}
    </span>
   
  </span>
  {type && <Badge name={`${type}`} color="green" />}
</div>

          <div className="flex items-center space-x-2">
            
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{packageName}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>  )}
            <span className="">{category}</span>
          </div>
   
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
          {price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
               /PERSON
              </span>
            )}
          </span>
          {!!totalReviews && (
            <StartRating reviewCount={totalReviews} point={averageRating?.toFixed(1)} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >{renderSliderGallery()}
   <Link
      
      to={`/listing-experiences-detail?pacakageName=${packageName}&id=${id}`} >    {renderContent()}
      
      </Link>
    </div>
  );
};

export default StayCard;

             // <svg
              //   xmlns="http://www.w3.org/2000/svg"
              //   className="h-5 w-5"
              //   fill="none"
              //   viewBox="0 0 24 24"
              //   stroke="currentColor"
              // >
              //   <path
              //     strokeLinecap="round"
              //     strokeLinejoin="round"
              //     strokeWidth={1.5}
              //     d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              //   />
              //   <path
              //     strokeLinecap="round"
              //     strokeLinejoin="round"
              //     strokeWidth={1.5}
              //     d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              //   />
              // </svg>