import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  LockClosedIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  SparklesIcon
} from "@heroicons/react/24/outline";
import { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import { UserContext, useAuthContext } from "App";
import { toast } from "react-hot-toast";
import axios from "axios";
import { API_URL } from "services/constants";
const solutions = [
  {
    name: "Account",
    href: "/account",
    icon: UserCircleIcon,
  },
  {
    name: "Bucket List",
    href: "/account-savelists",
    icon: HeartIcon,
  },
  {
    name: "Booking",
    href: "/booked-packages",
    icon: HomeIcon,
  },
  {
    name: "Coupons",
    href: "/account-coupons",
    icon: SparklesIcon
  },
  {
    name: "Credentials",
    href: "account-password",
    icon: LockClosedIcon,
  },
];

const solutionsFoot = [

  {
    name: "Logout",
    href: "##",
    icon: ArrowRightOnRectangleIcon,
  },
];

export default function AvatarDropdown() {
  const {isLoggedIn,setLoggedIn,loading,setLoading}=useAuthContext();
  const {userData,setUserData}=useContext(UserContext)


  useEffect(()=>{
    let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  
  let api = createApiInstance(authTokens.accessToken);
    setLoading(true)

    api.get('users/user_profile/').then((response)=>{
      setLoading(false)
      setUserData(response.data)
      
    }).catch((error:any)=>{
      setLoading(false)
    })

  },[isLoggedIn])
  useEffect(()=>{

  },[userData,isLoggedIn])
  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
             {userData.profile_image_url&& <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={`${userData.profile_image_url}`}/>}
             {!userData.profile_image_url&& <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" />}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutions.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        onClick={()=>{
                          if(item.name=='Logout'){localStorage.removeItem('authTokens')
                          setLoggedIn(false);
                          localStorage.removeItem('user');
                         
                          toast.success('You are Logged Out!')
                        }
                        }}
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
