import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC, useRef, useState } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";
import { useAuthContext } from "App";
import axios from "services/UnAuthAxiosInstance";


export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

interface Testimonial {
  id: number;
  client_name: string;
  client_place: string;
  client_image: string;
  content: string;
}


const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();
  const {setLoading} = useAuthContext()
  const [testimony,setTestimony] = useState<Testimonial[]>([])
  const glideRef = useRef<Glide | null>(null);
  useEffect(() => {
    setLoading(true)
    axios
      .get('home/testimonials/')
      .then((res) => {
        setTestimony(res.data);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

      });
  }, []);

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`) && testimony.length > 0) {
      setTimeout(() => {
        glideRef.current = new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        });
        glideRef.current.mount();

        // Start the automatic slide transition
        glideRef.current.play(3000); // Set the interval to 3000ms (3 seconds)
      }, 10);
    }

    return () => {
      // Clean up the carousel when the component unmounts
      if (glideRef.current) {
        glideRef.current.destroy();
        glideRef.current = null;
      }
    };
  }, [testimony]);
const images_list:string[] = []
testimony.map((obj)=>{
  images_list.push(obj.client_image)
})
  const renderBg = (images_list:any) => {
    return (
      <div className="hidden md:block">
      {images_list[6]&& <img className="absolute top-9 -left-20 rounded-full h-16 w-16" src={images_list[6]} alt="" />} 
 {images_list[1]&&      <img
          className="absolute bottom-[100px] right-full mr-40 rounded-full h-16 w-16"
          src={images_list[1]}
          alt=""
        />} 
{images_list[2]&&      <img
          className="absolute top-full left-[140px] rounded-full h-16 w-16"
          src={images_list[2]}
          alt=""
        />}  
  {images_list[3]&&     <img
          className="absolute -bottom-10 right-[140px] rounded-full h-16 w-16"
          src={images_list[3]}
          alt=""
        />} 
 {images_list[4]&&      <img
          className="absolute left-full ml-32 bottom-[80px] rounded-full h-16 w-16"
          src={images_list[4]}
          alt=""
        />} 
        {images_list[5]&&<img className="absolute -right-10 top-10  rounded-full h-16 w-16" src={images_list[5]} alt="" />}
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc="Let's see what people think of Enroute" isCenter>
        Good news from far away
      </Heading>
      <div className="relative md:mb-16 max-w-2xl mx-auto">

        {renderBg(images_list)}
        
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {testimony.map((item) => (
                <>
                
                <li
                  key={item.id }
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <img className="mx-auto rounded-full h-20 w-20" src={`${item.client_image}`} alt="" />
                  <span className="block text-2xl pt-5">{item.content}</span>
    
                  <span className="block mt-8 text-2xl font-semibold">
                    {item.client_name}
                  </span>
                  <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.client_place}</span>
                  </div>
                </li>
                </> ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {testimony.map((item, index) => (
              <button
                key={item.id}
                className='glide__bullet w-2 h-2 rounded-full mx-1 focus:outline-none bg-neutral-300 dark:bg-teal dark:bg-opacity-50'
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
