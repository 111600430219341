import { useAuthContext } from "App";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { API_URL } from "services/constants";
import { clientId, clientSecret } from "services/env";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  packageId?: number;
  setChange?:React.Dispatch<React.SetStateAction<boolean>>
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  packageId,
  setChange
}) => {
  let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  let api = createApiInstance(authTokens.accessToken);
  const [likedState, setLikedState] = useState(false);
  const {setLoading} = useAuthContext()
  useEffect(()=>{
    api.get(`packages/is_in_wishlist/${packageId}/`).then((response)=>{
      setLikedState( response.data)
    })

  })
  return (
    <div
      className={`nc-BtnLikeIcon w-5 h-5 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "nc-BtnLikeIcon--liked" : ""
      }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={() => {
    

    setLoading(true)
    api.post(`packages/add_wishlist/${packageId}/`).then((response)=>{
      setLoading(false)
      toast.success(response.data.message);
      setChange&&setChange(true)
    }).catch((error:any)=>{
      setLoading(false)
        if (error.response.status===401){
          toast.error('Please Login to use Bucket List')
        }
    })
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const tokenEndpoint = 'users/auth/token/';
          const refreshToken = authTokens.refreshToken;
    
          try {
            const response = await axios.post(tokenEndpoint, {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              client_id: clientId,
              client_secret: clientSecret,
            });
    
            const accessToken = response.data.access_token;
            authTokens = {
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
              exp: response.data.exp,
            };
            localStorage.setItem('authTokens', JSON.stringify(authTokens));
            api = createApiInstance(accessToken);
            return api.request(error.config);
          } catch (error) {
            // Handle the error
            // ...
          }
        }
    
        return Promise.reject(error);
      }
    );
    

        setLikedState(!likedState)}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
