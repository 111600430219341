import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuthContext } from "App";
import axios from "services/UnAuthAxiosInstance";
import { toast } from "react-hot-toast";


export interface PageContactProps {
  className?: string;
}
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,'Please type a valid email address')
    .email('Please type a valid email address'),
  fullName: Yup.string()
    .required('Password is required')
    .min(2, 'Full Name cannot be less than 2 characters ')
    .max(100, 'Write a shorter name'),
  message:Yup.string()
  .required('A non empty message is required')
});


const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Munnar road perumbavoor,\n Opposite Akbar Travels \n , Pin: 683542",
  },
  {
    title: "💌 EMAIL",
    desc: "booking@enroutetravel.co.in",
  },
  {
    title: "☎ PHONE",
    desc: "+91 89216 06208",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  type ContactForm = {
    fullName: string;
    email:string;
    message: string;
  }
  const {setLoading} = useAuthContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ContactForm>({
    resolver: yupResolver(validationSchema)
  });
 const onContactSubmit  =(data:ContactForm)=>{
  setLoading(true)

  
  axios.post('utilities/contactUs/',{
    name:data.fullName,
    email:data.email,
    message:data.message
  }).then((res)=>{
    setLoading(false)
    toast.success("Message sent")
    reset()
  }).catch((err)=>{
    setLoading(false)
    toast.error('something went wrong')
  })
 }
  
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Enroute@</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        {/* <p className="my-16 sm:my-20 flex items-center text-3xl leading-[50%] md:text-1xl md:leading-[50%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">For any queries, please do contact our team</p> */}
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onContactSubmit)}>
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"

                    className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                    {...register('fullName')}
                    name='fullName'
                  />
                  <div className="text-red-500 text-xs mt-2 mx-2">{errors.fullName?.message}</div>
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="text"
                    placeholder="example@example.com"
                    className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                    {...register('email')}
                    name='email'
                  />
                  <div className="text-red-500 text-xs mt-2 mx-2">{errors.email?.message}</div>
                </label>
                
                <label className="block">
                  
                  <Label>Message</Label>

                  <Textarea rows={6} 
                       className={`mt-1 ${errors.message ? 'border border-red-500' : ''}`}
                       {...register('message')}
                       name='message'
                  />
              <div className="text-red-500 text-xs mt-2 mx-2">{errors.message?.message}</div>
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
