import React, { useContext } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ClientInformation, InvoiceDataContext, User } from '../PayPage';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36,
        justifyContent: 'flex-start',
        width: '50%'
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
});

const BillTo = ({billTo}:{billTo:User}) =>{ 
    const {invoiceData}=useContext(InvoiceDataContext)
    return(
    
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Billed To: {billTo.userName}</Text>
        <Text  >{billTo.email}</Text>
        <Text>{billTo.phoneNumber}</Text>

    </View>
)};

export default BillTo;