import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ClientInformation } from '../PayPage';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const InvoiceTableFooter = ({tableData}:{tableData:ClientInformation}) => {

    return (
        <>
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>{
              ( (tableData.adults*tableData.price)+(tableData.children*tableData.price/2)-(tableData.coupon_discount))
            }</Text>
        </View>
                <View style={styles.row}>
                <Text style={styles.description}>ADVANCE PAID</Text>
            <Text style={styles.total}>{
              ( (tableData.adults*tableData.price)+(tableData.children*tableData.price/2)-(tableData.coupon_discount))/2
            }</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>BALANCE AMOUNT</Text>
            <Text style={styles.total}>{
              ( (tableData.adults*tableData.price)+(tableData.children*tableData.price/2)-(tableData.coupon_discount))/2
            }</Text>
            </View>
            </>
    )
};

export default InvoiceTableFooter;