import React, { FC, useState,useContext } from "react";


import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import login from '../../services/login'
import toast, { Toaster } from 'react-hot-toast';
import Badge from "shared/Badge/Badge";
import { UserContext, useAuthContext } from "App";
import forgotPass, { changePassword, sendOtp } from "services/forgotPass";
import ButtonCircle from "shared/Button/ButtonCircle";
import { replace } from "lodash";
import axios from "axios";
import { API_URL } from "services/constants";
import AuthContext from "contexts/AuthContext";

export interface PageforgotPassProps {
    className?: string;
  }
export interface AfterSubmitPassProps {
    email?: string;
  }
  type EmailSubmitForm = {
    email: string;
  };

  type PasswordsubmitForm = {
    password: string;
    confirmPassword: string;
  }

const AfterSubmit:FC<AfterSubmitPassProps>= ({email}) =>{
    const {setLoggedIn} = useAuthContext()
    const {setLoading,isLoggedIn} = useContext(AuthContext)
const {userData,setUserData}=useContext(UserContext)
    const validationSchema = Yup.object().shape({
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'),''], 'Confirm Password does not match'),
      });
    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm<PasswordsubmitForm>({
        resolver: yupResolver(validationSchema)
      });
      const navigate = useNavigate()
     
     const onSubmit = (data:PasswordsubmitForm)=>{
        let token = localStorage.getItem('jwt_token')
        toast.promise(
            changePassword(data.password,token),
            {
              loading: 'Updating',
              success: (response) => {
                localStorage.removeItem('jwt_token');
                let authtokens:any = {
                    'accessToken': response.data?.access_token,
                    'refreshToken': response.data?.refresh_token,
                    'exp':response.data?.expires_in
                }
                localStorage.setItem('authTokens',JSON.stringify(authtokens))
                setLoggedIn(true)
const checkoutRedirect = sessionStorage.getItem("checkoutRedirect")

const createApiInstance = (accessToken:string) => {
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

let api = createApiInstance(authtokens.accessToken);
api.get('users/user_profile/').then((response)=>{
  setLoading(false)
  setUserData(response.data)
  
}).catch((error:any)=>{
  setLoading(false)
})
                if (checkoutRedirect) {
                  // Redirect user back to checkout page
                  navigate(checkoutRedirect)
                  // Clear stored URL from session storage
                  sessionStorage.removeItem("checkoutRedirect");
                }else{
                  navigate('/')
                }
                return <>`Password updated successfully` </>},
              error: (err) =>{
                return ` ${err.response.data.error} `} ,
            },
            {
              style: {
                minWidth: '300px',
                maxWidth: '500px',
                color:'#115E59'
              },
              success: {
                duration: 5000,
                icon: '🙌',
              },
            }
          );
      }

    return (
    
                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                New Password
                </span>
                <Input type="password"
                className={`mt-1 ${errors.password ? 'border border-red-500' : ''}`}
                {...register('password')}/>
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.password?.message}</div>
                </label>
                <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
                </span>
                <Input type="password"
                className={`mt-1 ${errors.confirmPassword ? 'border border-red-500' : ''}`}
                {...register('confirmPassword')}/>
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.confirmPassword?.message}</div>
                </label>
                <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </form>
    )
}

const PageForgotPass:FC<PageforgotPassProps> = ({className = ""})=>{
    const [message,setMessage]=useState('')
    const [reqSent,setreqsent]=useState(false)
    const [otp,setotp]=useState('')
    const [email,setemail]=useState('')
    const [otpVerified,setotpverified]=useState(false)
    const [errrs,seterrrs]=useState(true)
    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .required('Email is required')
          .email('Enter a valid email address')})
    const {isLoggedIn}=useAuthContext()
    {isLoggedIn&& <Navigate replace to='/account'/>}
    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm<EmailSubmitForm>({
        resolver: yupResolver(validationSchema)
      });
      const onSubmit = (data:EmailSubmitForm) =>{
        toast.promise(
            forgotPass(data.email),
            {
              loading: 'Sending OTP',
              success: (response) => {
                setreqsent(true);
                setMessage('Please check your email and enter OTP below 😊')
                setemail(data.email)
                seterrrs(false)
                return `Sent OTP to ${data.email} `},
              error: (err) =>{
                seterrrs(true)
                return ` ${err.response.data.error} `} ,
            },
            {
              style: {
                minWidth: '300px',
                maxWidth: '500px',
                color:'#115E59'
              },
              success: {
                duration: 5000,
                icon: '🙌',
              },
            }
          );
      }

    const  otpHandler = ()=>{
        sendOtp(email,otp).then((response)=>{
            toast.success('OTP Verification Successful')
            setreqsent(false);
            setotpverified(true);
            setMessage('Change your password ')
            seterrrs(false)
            localStorage.setItem('jwt_token',response.data.token)

        }).catch((error)=>{
            setotpverified(false);
            setMessage('Check your otp again')
            toast.error(`${error.response.data.error}`)
            seterrrs(true)
        })
    }
    return (
    
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
          <Helmet>
            <title>Login || Enroute Travel</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            {!otpVerified &&
            
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Forgot Password
            </h2>}
            {otpVerified && 
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Update Password
          </h2>}
            <div className="max-w-md mx-auto space-y-6">
              {/* OR */}
              {/* FORM */}
              {!otpVerified &&<>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="text"
                    placeholder="Enter your registered email address"
                    className={`mt-1 ${errors.email ? 'border border-red-500' : ''}`}
                    {...register('email')}
                    name='email'
                    onChange={()=>{setreqsent(false);setMessage('')}}
                  />
                <div className="text-red-500 text-xs mt-2 mx-2">{errors.email?.message}</div>
                </label>
                <ButtonPrimary type="submit">Continue</ButtonPrimary>
              </form>
              {reqSent &&
               <Badge color={`${errrs?'red':'green'}`} name={`${message}`} className="items-center" />
              }
              
              {isLoggedIn&& <Navigate replace to='/account'/>}
              {/* ==== */}
           
              {!reqSent &&
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                    New user? {` `}
                    <Link to="/signup">Create an account</Link>
                  </span>
              }
              {
                reqSent && 
                <div className="mt-10 relative max-w-sm">
                <Input
                required
                aria-required
                placeholder="Enter OTP"
                type="text"
                rounded="rounded-full"
                name="otp"
                onChange={(e)=>{
                    setotp(e.target.value);
                }}
              />
              <ButtonCircle
                className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                onClick={otpHandler}
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              </div>

              }
              
              </>}

              {otpVerified && <>
                <AfterSubmit email={email} />
              
              </>}




            </div>
          </div>
        </div>
      );

}

export default PageForgotPass;




