import { createContext } from 'react';

export interface AuthContextType {
  isLoggedIn: boolean;
  setLoggedIn: (isLoggedIn: boolean) => void; 
  loading: boolean;
  setLoading: (loading: boolean) => void;

}

const AuthContext = createContext<AuthContextType>({
  isLoggedIn: localStorage.getItem('authTokens')?true:false,
  setLoggedIn: (isLoggedIn: boolean) => {
      isLoggedIn=true;
  },
  loading:false,
  setLoading: (loading: boolean) => {loading=false}

});


export default AuthContext;


