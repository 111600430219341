import { UserContext } from "App";
import axios from "axios";
import StartRating from "components/StartRating/StartRating";
import AuthContext from "contexts/AuthContext";
import { PackageDataContext } from "contexts/packageDataContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "services/constants";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PdfDocument from "./invoice";
import { createContext } from "react";



export interface PayPageProps {
  className?: string;
}
 export interface BookingData {
    coupon: null|number;
    date_booked: string;
    end_date: string;
    id: number;
    num_adults: number;
    num_children: number;
    num_infants: number;
    package: number;
    payment: number;
    phone_number: string;
    start_date: string;
    user: number;
    booking_code: string;
  }
  export interface User {
    userName: string;
    email: string;
    phoneNumber: string;
  }
  
  export interface PackageDetail {
    packageName: string;
    packagePlace: string;
    price: number;
  }
  
 export  interface ClientInformation {
    adults: number;
    children: number;
    infants: number;
    price: number;
    coupon_discount: number;
  }
  
  export interface InvoiceData {
    invoiceId: string;
    user: User;
    packageDetail: PackageDetail;
    clientInformation: ClientInformation;
  }
  interface InvoiceDataContextValue {
    invoiceData: InvoiceData;
  }
  
  export const InvoiceDataContext = createContext<InvoiceDataContextValue>({
    invoiceData: {
      invoiceId: "",
      user: {
        userName: "",
        email: "",
        phoneNumber: "",
      },
      packageDetail: {
        packageName: "",
        packagePlace: "",
        price: 0,
      },
      clientInformation: {
        adults: 0,
        children: 0,
        infants: 0,
        price: 0,
        coupon_discount: 0,
      },
    },
  });
  


  
const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const location = useLocation()
  const [bookingData, setBookingData] = useState<BookingData>(location.state);
  useEffect(()=>{

  },[])
  const {userData} = useContext(UserContext)
  const {packageData}  =useContext(PackageDataContext)
  const startDate = new Date(bookingData.start_date);
  const endDate = new Date(bookingData.end_date);
  const startMonth = startDate.toLocaleString('default', { month: 'short' });
  const endMonth = endDate.toLocaleString('default', { month: 'short' });
  const formattedStartDate = `${startMonth} ${startDate.getDate()}`
  const formattedEndDate = `${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
  const finalDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  const booked_date  = bookingData.date_booked.slice(0, bookingData.date_booked.indexOf('T'))
  const {setLoading} = useContext(AuthContext)
  const [couponData,setCouponData]=useState({
    advance_paid:0,
    coupon_discount:0,
  })
  let authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens')!)
  : { accessToken: '', refreshToken: '', exp: '' };
  const createApiInstance = (accessToken:string) => {
    return axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };
  let api = createApiInstance(authTokens.accessToken);
  useEffect(()=>{
    setLoading(true)
    api.get(`booking/payment_completed/?booking=${bookingData.id}`,).then((response) => {
      setLoading(false)

      setCouponData(response.data)
    })
    
  },[])
const [invoiceData,setInvoiceData]=useState<InvoiceData>(
   {
    invoiceId: "",
    user: {
      userName: "",
      email: "",
      phoneNumber: "",
    },
    packageDetail: {
      packageName: "",
      packagePlace: "",
      price: 0,
    },
    clientInformation: {
      adults: 0,
      children: 0,
      infants: 0,
      price: 0,
      coupon_discount: 0,
    },
  },
)
let Data
  useEffect(()=>{
     let Data = {
      invoiceId:bookingData.booking_code,
      user:{
        userName:userData.first_name+' '+userData.last_name,
        email:userData.email,
        phoneNumber:bookingData.phone_number
      },
      packageDetail:{
        packageName:packageData.name,
        packagePlace:packageData.main_place,
        price:packageData.discount_price>0?packageData.discount_price:packageData.price
  
      },
      clientInformation:{
        adults:bookingData.num_adults,
        children:bookingData.num_children,
        infants:bookingData.num_infants,
        price:packageData.discount_price>0?packageData.discount_price:packageData.price,
        coupon_discount:couponData?.coupon_discount
      }
  
  
    }
        setInvoiceData(Data)
  },[bookingData,couponData])
  useEffect(()=>{

  },[invoiceData])
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-2xl lg:text-3xl font-semibold text-teal-800 dark:text-teal-500">
          Thank you For Booking With US 
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your booking</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={packageData.images[0].image_url} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {packageData.main_place}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                {packageData.name}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {packageData.duration}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating point={packageData.average_rating?.toFixed(1)} reviewCount={packageData.total_reviews} />
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                      {finalDateRange}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {bookingData.num_adults+bookingData.num_children<2?`${bookingData.num_adults+bookingData.num_children} Guest`
                  :`${bookingData.num_adults+bookingData.num_children} Guests`} {bookingData.num_infants>0&&'&'&&
                    bookingData.num_infants>1?`${bookingData.num_infants} Infants`:`${bookingData.num_infants} Infant`
                  }  </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {bookingData.booking_code}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booked_date}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total Amount</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {(couponData.advance_paid*2).toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
              </span>
            </div>
            {couponData.coupon_discount>0&&
            <div className="flex text-teal-700 dark:text-teal-300">
              <span className="flex-1">Coupon Reduction</span>
              <span className="flex-1 font-medium text-teal-700 dark:text-teal-300">
                {(couponData.coupon_discount).toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
              </span>
            </div>
            }
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total Advance Paid</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {(couponData.advance_paid).toLocaleString("en-IN", {
                                                                      style: "currency",
                                                                      currency: "INR",
                                                                    })}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Balance Amount</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {(couponData.advance_paid).toLocaleString("en-IN", {
                                                                      style: "currency",
                                                                      currency: "INR",
                                                                    })}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                Online Payment
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
  <div>
    <ButtonSecondary href="/listing-stay">Explore more stays</ButtonSecondary>
  </div>
  <div>
    {/* <ButtonPrimary href="/listing-stay">Download Invoice</ButtonPrimary> */}
    <InvoiceDataContext.Provider value={{ invoiceData }}>
      <ButtonPrimary>
    <PDFDownloadLink
          document={<PdfDocument invoiceData={invoiceData} />}
          fileName='Booking invoice'
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : "Download Invoice"
          }
        </PDFDownloadLink>
        </ButtonPrimary>
      </InvoiceDataContext.Provider>
  </div>
</div>

      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;