import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import logo from '../../../Enroute Files/logo.png'
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { includes_demo, PHOTOS } from "./constant";
import StayDatesRangeInput from "./StayDatesRangeInput";
import GuestsInput from "./GuestsInput";
import qs from "qs";
import UnAuthAxiosInstance from "services/UnAuthAxiosInstance";
import AuthContext from "contexts/AuthContext";
import { toast } from "react-hot-toast";
import { Console } from "console";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import MobileFooterSticky from "../(components)/MobileFooterSticky";
import { StaySearchContext } from "App";
import { PackageDataContext } from "contexts/packageDataContext";
import axios from "axios";
import { API_URL } from "services/constants";




const ListingExperiencesDetail: FC<{}> = () => {
  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {setLoading,isLoggedIn,loading}=useContext(AuthContext)
  const [modal,setModal]= useState(false)
  const [review,setReview] = useState('')

const {guestAdultsInputValue,guestChildrenInputValue,guestInfantsInputValue,setGuestAdultsInputValue
,setGuestChildrenInputValue,setGuestInfantsInputValue,setEndDate,endDate,startDate,setStartDate}=useContext(StaySearchContext)
  const {packageData,setPackageData}  =useContext(PackageDataContext)
  const [images,setImages] = useState([])
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    setLoading(true)
   UnAuthAxiosInstance.get(`packages/package_detail/${searchParams.id}/`).then((res)=>{
    
    setImages(res.data.images)
    setLoading(false)
    setPackageData(res.data.packageData)

    localStorage.setItem('packageData', JSON.stringify(res.data.packageData))

   }).catch((err)=>{
    setLoading(false)
    toast.error('Something went wrong')
    
   })
  },[loader])
  useEffect(()=>{

  },[packageData,modal])
  useEffect(()=>{
    const getDurationDays = (duration: string) => {
      const match = duration.match(/^\d+/);
      const days = match ? parseInt(match[0]) : undefined;
      return days;
    };
    const durationDays = getDurationDays(packageData.duration);
    if (durationDays !== undefined) {
      const endDate = startDate&&new Date(startDate.getTime() + durationDays * 24 * 60 * 60 * 1000);
      setEndDate(endDate);

    } else {
      setEndDate(null);
    }  
  },[])
  let price = packageData.discount_price?packageData.discount_price:packageData.price
  const handleOpenModalImageGallery = () => {
    setModal(true)
  };

  const handleCloseModalImageGallery = () => {
    setModal(false)

  };
  const handleReviewSubmit = () =>{
    let authTokens = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens')!)
    : { accessToken: '', refreshToken: '', exp: '' };
    const createApiInstance = (accessToken:string) => {
      return axios.create({
        baseURL: API_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    };
    
    
    let api = createApiInstance(authTokens.accessToken);
      setLoading(true)
    api.post(`packages/add_review/${packageData.id}/`,{'review_text':review,'rating':point}).then((response)=>{
      setLoading(false)
      toast.success('Thank you for adding your opinion')
      setLoader(true)
    }).catch((error)=>{
      setLoading(false)
      toast.error(error.response.data.error)

      setLoader(true)

    })
  }
  const [point, setPoint] = useState(0);
  
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge color="pink" name={`${packageData.type}`} href={`/listing-stay?type=${packageData.type}`} />
          <LikeSaveBtns itinerary={packageData.itinerary_pdf} packageId = {packageData.id}/>
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {packageData.name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating point={packageData.average_rating?.toFixed(1)} reviewCount={packageData.total_reviews}/>
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {packageData.main_place}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar imgUrl={logo} hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Enroute
            </span>
            
          </span>
        </div>
        <a href="tel:+91-89216 06208"><i className="la la-tty text-2xl"></i> Call Us</a>

<a href="https://wa.me/message/PF6JOAB7EZ5OM1" target="_blank"><i className="lab la-whatsapp text-2xl"></i> Say Hi</a>



        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{packageData.duration}</span>
          </div>
          {/* <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">Up to 10 people</span>
          </div> */}
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-cloud text-2xl"></i>
            <span className="">{packageData.category}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Package Itinerary</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {/* <p>
            
                  </p> */}
        {packageData.itinerary.map((item) => (
          <React.Fragment key={item.id}>
            <h6 className="font-semibold text-1xl">Day {item.day}</h6>
            <p>{item.activity}</p>
            <br />
          </React.Fragment>
        ))}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Inclusions </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">

          {
          
          packageData.inclusions
            .filter((_, i) => i < 12)
            .map((item) => (
    
              <div key={item} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.replace(/['\[\]]/g, '')}</span>
              </div>

            ))}
        </div>
      </div>
    );
  };
  const renderSection9 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Exclusions </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Excluded in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">

          {
          
          packageData.exclusions
            .filter((_, i) => i < 12)
            .map((item) => (
    
              <div key={item} className="flex items-center space-x-3">
                <i className="las la-times-circle text-2xl"></i>
                <span>{item.replace(/['\[\]]/g, '')}</span>
              </div>

            ))}
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews ({packageData.reviews.length} reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" point = {point} setPoint ={setPoint} defaultPoint={5}/>
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
              onChange={(e)=>{
                setReview(e.target.value);
              }}
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
              onClick={handleReviewSubmit}
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {packageData.reviews.length>0&& packageData.reviews.slice(0,10).map((review)=>(
          <CommentListing className="py-8" key={review} data={{name:review.user.username!=' '?review.user.username:review.user.email
            ,date:review.created_at,comment:review.review_text,starPoint:review.rating,avatar:review.user.profile_image,has_booked:review.has_booked}} />
          ))}

          <div className="pt-8">
            <ButtonSecondary>View more reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Locations</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {packageData.places.map((item)=>{
              return <>
              {`     `}
              <i className="las la-map-marker " key={item}></i>
              {item.replace(/['\[\]]/g, '')} 
               </>
            })}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        {packageData.location&&
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`${packageData.location}`}
            ></iframe>
          </div>
        </div>
        }
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>11:30 AM</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>10:00 AM</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">Guest Policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          
                      You have to pay 50% of the package in advance at the time of booking. The full amount needs to be paid before departure.
                      <br /><br/>All activities which are not mentioned in the above itinerary such as visiting additional spots or involving in paid activities etc will be charged.
                      In case of using additional transport will be chargeable.
                      <br /><br/>All transport on the tour will be grouped. ( Only for Group Tour)
                      <br /><br/>In Case Of Events And Circumstances Beyond Our Control, We Reserve The Right To Change All Or Parts Of The Contents Of The Itinerary For the Safety And Well Being Of Our Esteemed Passengers.
                      <br /><br/>Please don't bring any valuable ornaments like gold or diamond rings etc Every team member should have a valid photo ID Card.
                                </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Guest requirements</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Up to 10 guests ages 4 and up can attend. Parents may also bring
            children under 2 years of age.
          </span>
        </div> */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation Policy</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
              Before 45 days prior to the check-in date  70% of the advance amount will be refunded
              </li>
              <li>Before 30 days 50%  of advance amount 
</li>
              <li>Before 15 days 
              30% of advance amount
              </li>
              <li>Before 10 days 
              20% of advance amount
              </li>
              <li>Before 5 days No amount will be refunded</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
        <span className="text-1xl text-red-600 line-through">{packageData.discount_percent>0&&` ₹${packageData.price}`}</span>
          <span className="text-2xl font-semibold">
          
          {price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            /PERSON
            </span>
          </span>
          <StartRating reviewCount={packageData.total_reviews} point={packageData.average_rating?.toFixed(1)}/>
        </div>

        {/* FORM */}
        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput className="flex-1 z-[11]"     
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            duration={packageData.duration} />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1"
           guestAdultsInputValue={guestAdultsInputValue} guestChildrenInputValue={guestChildrenInputValue}  
           guestInfantsInputValue={guestInfantsInputValue} setGuestAdultsInputValue={setGuestAdultsInputValue}
           setGuestChildrenInputValue={setGuestChildrenInputValue} setGuestInfantsInputValue={setGuestInfantsInputValue}
           />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {guestAdultsInputValue&& <span> {price.toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})} x {guestAdultsInputValue} {guestAdultsInputValue>1?'Adults':'Adult'} </span> }
            <span>{(price*guestAdultsInputValue).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {guestChildrenInputValue&& <span> {(price/2).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})} x {guestChildrenInputValue} {guestChildrenInputValue>1?'Children':'Child'} </span> }
            <span>{(price*guestChildrenInputValue/2).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {guestInfantsInputValue&& <span> <b>₹</b>{0} x {guestInfantsInputValue} {guestInfantsInputValue>1?'Infants':'Infant'} </span> }
            <span><b>₹</b>{0}</span>
          </div>
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div> */}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{(price*guestAdultsInputValue+price*guestChildrenInputValue/2).toLocaleString("en-IN", {
  style: "currency",
  currency: "INR",
})}</span>
          </div>
        </div>

        {/* SUBMIT */}
        {guestAdultsInputValue>=1&&endDate&&packageData.is_active&& <ButtonPrimary href={`${isLoggedIn?'/checkout':'/login'}`} onClick={()=>{
            if (!isLoggedIn) {
              const redirectUrl = `${window.location.pathname}${window.location.search}`
              sessionStorage.setItem("checkoutRedirect", redirectUrl);
              router("/login");
            }
          !isLoggedIn&&toast.error('You must login for booking packages')}} >Book</ButtonPrimary>}
        
        {guestAdultsInputValue<1||!endDate||!packageData.is_active?<ButtonPrimary disabled={true} onClick={()=>!isLoggedIn&&toast.error('You must login for booking packages')} >Book</ButtonPrimary>:<></>}

      </div>
    );
  };

  return (
    <div className={` nc-ListingExperiencesDetailPage `}>
      {/* SINGLE HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}

          >
           {modal&& <ListingImageGallery
         
        isShowModal={modal}
        onClose={handleCloseModalImageGallery}
        images={images.map(
          (item, index): ListingGalleryImage => {
            return {
              id: index,
              url: item,
            };
          }
        )}
      />}

            <img
              alt="photos 1"
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={images[0]}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {images.filter((_, i) => i >= 1 && i < 4).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 2 ? "block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3">
                <img
                  alt="photos"
                  className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={item || ""}
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="h-5 w-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </div>
        </div>
      </header>

      {/* MAIn */}
      <main className="relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSection9()}
          <SectionDateRange/>

          {/* {renderSection5()} */}
          {renderSection6()}
          {renderSection7()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingExperiencesDetailPage() {
  
  return (
    <DetailPagetLayout>
      <ListingExperiencesDetail />
      <MobileFooterSticky  />
    </DetailPagetLayout>
  );
}
