import React from "react";
import { Navigate,useNavigate } from "react-router-dom";
import { useAuthContext } from "App";




export default function LoggedInChecker() {
    const {isLoggedIn,setLoggedIn} =useAuthContext(); 

    return(
        <>
        {!isLoggedIn && <Navigate to="/login"/> }
        </>
    )
}