import qs from "qs";
import React from "react";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = " All Packages ",
  subHeading,
}) => {
  const location = useLocation();
const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
const value = searchParams.value?searchParams.value:''
heading = value?`Packages in ${value}`:'All packages'
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
We have curated a selection of bespoke packages, designed exclusively for you, our valued clients.
        </span>
      )}
    </div>
  );
};

export default Heading2;
